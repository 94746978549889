import Image from "next/image";
import { FC, useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { processMathContent } from "@/utils/processMathContent";

type Props = {
  isUser: boolean;
  content?: string;
  photoData?: string;
  compact?: boolean;
};

const MessageReadonly: FC<Props> = ({
  isUser,
  content,
  photoData,
  compact = false,
}) => {
  const [str, setStr] = useState("");
  const previousContentRef = useRef("");

  useEffect(() => {
    if (!content) return;

    const process = async () => {
      const prevContent = previousContentRef.current;

      if (content !== prevContent) {
        const newContent = content.slice(prevContent.length);
        const processedNewContent = await processMathContent(newContent);
        setStr((prevStr) => {
          return prevStr === processedNewContent
            ? prevStr
            : prevStr + processedNewContent;
        });
        previousContentRef.current = content;
      }
    };

    process();
  }, [content]);

  return (
    <div
      className={classNames(
        "flex odd:bg-neutral-1 even:bg-white flex-col",
        compact ? "p-6" : "xl:flex-row xl:gap-8 gap-3 px-12 py-4"
      )}
    >
      <div
        className={classNames(
          "font-semibold w-16 shrink-0 text-left leading-7",
          compact ? "pb-2" : "xl:text-right py-2.5"
        )}
      >
        {isUser ? "You" : "Stella"}
      </div>
      <div
        className={classNames(
          "flex flex-col w-full",
          compact ? "leading-4" : "leading-7"
        )}
      >
        {photoData && (
          <Image
            alt="preview"
            src={photoData}
            className="object-cover rounded-lg border m-2.5"
            width={288}
            height={160}
          />
        )}
        <div className={classNames(!compact && "p-2.5")}>
          {!content && !isUser && (
            <div className="animate-pulse">Thinking...</div>
          )}
          {content && !isUser && (
            <div
              className="whitespace-pre-line leading-7.5"
              dangerouslySetInnerHTML={{ __html: str }}
            />
          )}
          {content && isUser && (
            <div
              className="whitespace-pre-wrap leading-7.5"
              dangerouslySetInnerHTML={{ __html: str }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default MessageReadonly;

import { Subject, Topic } from "./subjects";

export type MathsStandardTopicKey =
  | "algebra"
  | "measurement"
  | "financial_mathematics_standard"
  | "statistical_analysis_standard"
  | "networks";

export type MathsStandardSubjectKey = "maths_standard";

export const maths_standard_topics: Record<MathsStandardTopicKey, Topic> = {
  algebra: {
    name: "Algebra",
    formulas: [],
  },
  measurement: {
    name: "Measurement",
    formulas: [
      `Absolute error\(=\frac{1}{2}\times\)precision`,
      `Upper bound=measurement+absolute error`,
      `Lower bound=measurement-absolute error`,
      `\[l=\frac{\theta}{360}\times2\pi r\]`,
      `\[A=\frac{\theta}{360}\times\pi r^{2}\]`,
      `\[A=\frac{h}{2}(a+b)\]`,
      `\[A\approx\frac{h}{2}\left(d_{f}+d_{l}\right)\]`,
      `\[A=2\pi r^{2}+2\pi rh\]`,
      `\[A=4\pi r^{2}\]`,
      `\[V=\frac{1}{3}Ah\]`,
      `\[V=\frac{4}{3}\pi r^{3}\]`,
      `\[\sin A=\frac{\text{opp}}{\text{hyp}}\]`,
      `\[\quad\cos A=\frac{\text{adj}}{\text{hyp}}\]`,
      `\[\tan A=\frac{\text{opp}}{\text{adj}}\]`,
      `\[A=\frac{1}{2}ab\sin C\]`,
      `\[\frac{a}{\sin A}=\frac{b}{\sin B}=\frac{c}{\sin C}\]`,
      `\[c^{2}=a^{2}+b^{2}-2ab\cos C\]`,
      `\[\cos C=\frac{a^{2}+b^{2}-c^{2}}{2ab}\]`,
    ],
  },
  financial_mathematics_standard: {
    name: "Financial Mathematics",
    formulas: [`\[FV=PV(1+r)^{n}\]`, `\[S=V_{0}-Dn\]`, `\[S=V_{0}(1-r)^{n}\]`],
  },
  statistical_analysis_standard: {
    name: "Statistical Analysis",
    formulas: [
      `An outlier is a score less than \(Q_{1}-1.5\times IQR\) or more than \(Q_{3}+1.5\times IQR\)`,
      `\[z=\frac{x-\mu}{\sigma}\]`,
      `In normal distribution, approximately \(68\%\) of scores have \(z\)-scores between -1 and 1, approximately \(95\%\) of scores have \(z\)-scores between -2 and 2, approximately \(99.7\%\) of scores have \(z\)-scores between -3 and 3`,
    ],
  },
  networks: {
    name: "Networks",
    formulas: [],
  },
};

export const maths_standard_subjects: Record<MathsStandardSubjectKey, Subject> =
  {
    maths_standard: {
      name: "Maths Standard",
      topicKeys: [
        "algebra",
        "measurement",
        "financial_mathematics_standard",
        "statistical_analysis_standard",
        "networks",
      ],
    },
  };

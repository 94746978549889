import { Subject, Topic } from "./subjects";

const physics_constants = `\(q_{\mathrm{e}}\)=\(-1.602\times10^{-19}\mathrm{C}\),\(m_{\mathrm{e}}\)=\(9.109\times10^{-31}\mathrm{~kg}\),\(m_{\mathrm{n}}\)=\(1.675\times10^{-27}\mathrm{~kg}\),\(m_{\mathrm{p}}\)=\(1.673\times10^{-27}\mathrm{~kg}\),Speed of sound in air=\(340\mathrm{~m}\mathrm{~s}^{-1}\),\(g\)=\(9.8\mathrm{~m}\mathrm{~s}^{-2}\),\(c\)=\(3.00\times10^{8}\mathrm{~m}\mathrm{~s}^{-1}\),\(\varepsilon_{0}\)=\(8.854\times10^{-12}\mathrm{~A}^{2}\mathrm{~s}^{4}\mathrm{~kg}^{-1}\mathrm{~m}^{-3}\),\(\mu_{0}\)=\(4\pi\times10^{-7}\mathrm{~N}\mathrm{~A}^{-2}\),\(G\)=\(6.67\times10^{-11}\mathrm{~N}\mathrm{~m}^{2}\mathrm{~kg}^{-2}\),\(M_{\mathrm{E}}\)=\(6.0\times10^{24}\mathrm{~kg}\),\(r_{\mathrm{E}}\)=\(6.371\times10^{6}\mathrm{~m}\),\(h\)=\(6.626\times10^{-34}\mathrm{~J}\mathrm{~s}\),\(R\)(hydrogen)=\(1.097\times10^{7}\mathrm{~m}^{-1}\),\(u\)=\(1.661\times10^{-27}\mathrm{~kg}\)=\(931.5\mathrm{MeV}/c^{2}\),\(1\mathrm{eV}\)=\(1.602\times10^{-19}\mathrm{~J}\),
\(\rho\)=\(1.00\times10^{3}\mathrm{~kg}\mathrm{~m}^{-3}\),Specific heat capacity of water=\(4.18\times10^{3}\mathrm{~J}\mathrm{~kg}^{-1}\mathrm{~K}^{-1}\),\(b\)=\(2.898\times10^{-3}\mathrm{~m}\mathrm{~K}\)`;

export type PhysicsTopicKey = "physics_mod_one" | "physics_mod_two" | "physics_mod_three" | "physics_mod_four" 
| "physics_mod_five" | "physics_mod_six" | "physics_mod_seven" 
| "physics_mod_eight";

export type PhysicsSubjectKey = "physics";

export const physics_topics: Record<PhysicsTopicKey, Topic> = { 
    physics_mod_one: {
      name: "Mod 1 (Kinematics)",
      formulas: [
        `\[s=ut+\frac{1}{2}at^2\]`,
        `\[v^2=u^2+2as\]`,
        `\[v=u+at\]`,
      ],
      constants: physics_constants,
    },
    physics_mod_two: {
      name: "Mod 2 (Dynamics)",
      formulas: [
        `\[\Delta U=mg\Delta h\]`,
        `\[P=\frac{\Delta E}{\Delta t}\]`,
        `\[\sum\frac{1}{2}m v_{\text{before}}^2=\sum\frac{1}{2}mv_{\text{after}}^2\]`,
        `\[\Delta\vec{p}=\vec{F}_{\text{net}}\Delta t\]`,
        `\[\vec{F}_{\text{net}}=m\vec{a}\]`,
        `\[W=F_{||^{s}}=Fs\cos\theta\]`,
        `\[K=\frac{1}{2}mv^2\]`,
        `\[P=F_{||}v=Fv\cos\theta\]`,
        `\[\sum m\vec{v}_{\text{before}}=\sum m\vec{v}_{\text{after}}\]`,
      ],
      constants: physics_constants,
    },
    physics_mod_three:{
      name: "Mod 3 (Waves and Thermodynamics)",
      formulas: [
        `\[v=f\lambda\]`,
        `\[f=\frac{1}{T}\]`,
        `\[\quad f_{\text{beat}}=|f_{2}-f_{1}|\]`,
        `\[\quad f^{\prime}=f \frac{(v_{\text{wave}}+v_{\text{observer}})}{(v_{\text{wave}}-v_{\text{source}})}\]`,
        `\[n_{\mathrm{x}}=\frac{c}{v_{\mathrm{x}}}\] `,
        `\[Q=mc\Delta T\]`,
        `\[n_{1}\sin\theta_{1}=n_{2}\sin\theta_{2}\]`,
        `\[\quad \sin\theta_{\mathrm{c}}=\frac{n_{2}}{n_{1}}\]`,
        `\[\quad I_{1}r_{1}^2=I_{2}r_{2}^2\]`,
        `\[\quad \frac{Q}{t}=\frac{kA\Delta T}{d}\]`,
    ],
    constants: physics_constants,
    }, 
    physics_mod_four: {
      name: "Mod 4 (Electricity and Magnetism)",
      formulas: [
        `\[E=\frac{V}{d}\]`,
        `\[V=\frac{\Delta U}{q}\]`,
        `\[W=qV\]`,
        `\[B=\frac{\mu_{0}I}{2\pi r}\]`,
        `\[B=\frac{\mu_{0}NI}{L}\]`,
        `\[\vec{F}=q\vec{E}\]`,
        `\[F=\frac{1}{4\pi\varepsilon_{0}}\frac{q_{1}q_{2}}{r^{2}}\]`,
        `\[I=\frac{q}{t}\]`,
        `\[V=IR\]`,
        `\[P=VI\]`,
      ],
      constants: physics_constants,
    }, 
    physics_mod_five: {
      name: "Mod 5 (Advanced Mechanics)",
      formulas: [
        `\[\omega=\frac{\Delta\theta}{t}\]`,
        `\[\tau=r_{\perp}F=rF\sin\theta\]`,
        String.raw`\[\upsilon=\frac{2\pi r}{T}\]`,
        `\[\mathrm{U}=-\frac{GMm}{r}\]`,
        `\[a_{\mathrm{c}}=\frac{v^2}{r}\]`,
        `\[F_{\mathrm{c}}=\frac{mv^2}{r}\]`,
        `\[F=\frac{GMm}{r^2}\]`,
        `\[\frac{r^3}{T^2}=\frac{GM}{4\pi^2}\]`,
      ],
      constants: physics_constants,
    }, 
    physics_mod_six: {
      name: "Mod 6 (Electromagnetism)",
      formulas: [
        `\[W=qEd\]`,
        `\[\Phi=B_{\|}A=BA\cos\theta\]`,
        `\[\varepsilon=-N\frac{\Delta\Phi}{\Delta t}\]`,
        `\[\frac{V_{\mathrm{p}}}{V_{\mathrm{s}}} = \frac{N_{\mathrm{p}}}{N_{\mathrm{s}}}\]`,
        `\[F=qv_{\perp}B=qvB\sin\theta\]`,
        `\[F=lI_{\perp}B=lIB\sin\theta\]`,
        `\[\frac{F}{l}=\frac{\mu_{0}}{2\pi}\frac{I_{1}I_{2}}{r}\]`,
        `\[\tau=nIA_{\perp}B=nIAB\sin\theta\]`,
        `\[V_{\mathrm{p}}I_{\mathrm{p}}=V_{\mathrm{s}}I_{\mathrm{s}}\]`,
      ],
      constants: physics_constants,
    }, 
    physics_mod_seven: {
      name: "Mod 7 (The Nature of Light)",
      formulas: [
        `\[d\sin\theta=m\lambda \quad\]`,
        `\[I=I_{\max}\cos^2\theta\]`,
        `\[K_{\max}=hf-\phi\]`,
        `\[\lambda_{\max}=\frac{b}{T}\]`,
        `\[E=mc^2\]`,
        `\[t=\frac{t_{0}}{\sqrt{\left(1-\frac{v^{2}}{c^{2}}\right)}}\]`,
        `\[l=l_{0}\sqrt{\left(1-\frac{v^{2}}{c^{2}}\right)}\]`,
        `\[p_{\mathrm{v}}=\frac{m_{0}v}{\sqrt{\left(1-\frac{v^{2}}{c^{2}}\right)}}\]`,
      ],
      constants: physics_constants,
    }, 
    physics_mod_eight: {
      name: "Mod 8 (From the Universe to the Atom)",
      formulas: [
        `\[\lambda=\frac{h}{mv}\]`,
        `\[E=hf\]`,
        `\[\frac{1}{\lambda}=R\left(\frac{1}{n_{\mathrm{f}}^{2}}-\frac{41}{n_{\mathrm{i}}^{2}}\right)\]`,
        `\[N_{\mathrm{t}}=N_{0}e^{-\lambda t}\]`,
        `\[\lambda=\frac{\ln 2}{t_{\frac{1}{2}}}\]`,
      ],
      constants: physics_constants,
    },   
  };

export const physics_subjects: Record<PhysicsSubjectKey, Subject> = {
    physics: {
        name: "Physics",
        topicKeys: [
        "physics_mod_one",
        "physics_mod_two",
        "physics_mod_three",
        "physics_mod_four",
        "physics_mod_five",
        "physics_mod_six",
        "physics_mod_seven",
        "physics_mod_eight",
        ],
    },
};

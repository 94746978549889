import { Subject, Topic } from "./subjects";

const chemistry_constants = `\(N_{{A}}\)=\(6.022\times10^{23}\mathrm{mol^{-1}}\),Volume of 1 mole ideal gas at \(100\mathrm{kPa}\) and at \(0^{\circ}\mathrm{C}(273.15\mathrm{~K})\)=\( 22.71 \mathrm{~L} \),Volume of 1 mole ideal gas at \(100\mathrm{kPa}\) and at \(25^{\circ}\mathrm{C}(298.15\mathrm{~K})\)=\( 24.79 \mathrm{~L} \),Gas constant=\( 8.314 \mathrm{~J} \mathrm{~mol}^{-1} \mathrm{~K}^{-1} \),Ionisation constant for water at \(25^{\circ}\mathrm{C}(298.15\mathrm{~K}),K_{w}=1.0\times10^{-14}\),Specific heat capacity of water=\(4.18\times10^{3}\)`;
const chemistry_formulas = [
  `\[n=\frac{m}{MM}\]`,
  `\[c=\frac{n}{V}\]`,
  `\[PV=nRT\]`,
  `\[q=mc\Delta T\]`,
  `\[\Delta G^{\circ}=\Delta H^{\circ}-T\Delta S^{\circ}\]`,
  `\[\mathrm{pH}=-\log_{10}\left[\mathrm{H}^{+}\right]\]`,
  `\[pK_{a}=-\log_{10}\left[K_{a}\right]\]`,
  `\[A=\varepsilon l c=\log_{10}\frac{I_{o}}{I}\]`,
];

export type ChemistryTopicKey =
  | "chemistry_mod_one"
  | "chemistry_mod_two"
  | "chemistry_mod_three"
  | "chemistry_mod_four"
  | "chemistry_mod_five"
  | "chemistry_mod_six"
  | "chemistry_mod_seven"
  | "chemistry_mod_eight";

export type ChemistrySubjectKey = "chemistry";

export const chemistry_topics: Record<ChemistryTopicKey, Topic> = {
  chemistry_mod_one: {
    name: "Mod 1 (Properties and Structure of Matter)",
    formulas: chemistry_formulas,
    constants: chemistry_constants,
  },
  chemistry_mod_two: {
    name: "Mod 2 (Introduction to Quantitive Chemistry)",
    formulas: chemistry_formulas,
    constants: chemistry_constants,
  },
  chemistry_mod_three: {
    name: "Mod 3 (Reactive Chemistry)",
    formulas: chemistry_formulas,
    constants: chemistry_constants,
  },
  chemistry_mod_four: {
    name: "Mod 4 (Drivers of Reactions)",
    formulas: chemistry_formulas,
    constants: chemistry_constants,
  },
  chemistry_mod_five: {
    name: "Mod 5 (Equilibrium and Acid Reactions)",
    formulas: chemistry_formulas,
    constants: chemistry_constants,
  },
  chemistry_mod_six: {
    name: "Mod 6 (Acid/Base Reactions)",
    formulas: chemistry_formulas,
    constants: chemistry_constants,
  },
  chemistry_mod_seven: {
    name: "Mod 7 (Organic Chemistry)",
    formulas: chemistry_formulas,
    constants: chemistry_constants,
  },
  chemistry_mod_eight: {
    name: "Mod 8 (Applying Chemical Ideas)",
    formulas: chemistry_formulas,
    constants: chemistry_constants,
  },
};

export const chemistry_subjects: Record<ChemistrySubjectKey, Subject> = {
  chemistry: {
    name: "Chemistry",
    topicKeys: [
      "chemistry_mod_one",
      "chemistry_mod_two",
      "chemistry_mod_three",
      "chemistry_mod_four",
      "chemistry_mod_five",
      "chemistry_mod_six",
      "chemistry_mod_seven",
      "chemistry_mod_eight",
    ],
  },
};

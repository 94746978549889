import { unified } from "unified";
import remarkParse from "remark-parse";
import remarkMath from "remark-math";
import remarkRehype from "remark-rehype";
import rehypeKatex from "rehype-katex";
import rehypeStringify from "rehype-stringify";
import "katex/dist/katex.min.css";

export async function processMathContent(mathContentStr: string) {
  //   // Replace double backslashes with single backslashes
  //   const singleBackslashStr = mathContentStr.replace(/\\\\/g, "\\");

  //   // Replace \( and \) with $ for inline math
  //   const inlineMathStr = singleBackslashStr
  //     .replace(/\\\(/g, "$")
  //     .replace(/\\\)/g, "$");

  //   // Replace \[ and \] with $$ for display math
  //   const displayMathStr = inlineMathStr
  //     .replace(/\\\[/g, "$$")
  //     .replace(/\\\]/g, "$$");

  const result = await unified()
    .use(remarkParse)
    .use(remarkMath)
    .use(remarkRehype)
    .use(rehypeKatex)
    .use(rehypeStringify)
    .process(mathContentStr);

  return result.toString();
}

import { Subject, Topic } from "./subjects";

export type MathsAdvExtTopicKey =
  | "financial_mathematics"
  | "functions"
  | "logarithmic_and_exponential_functions"
  | "trigonometric_functions"
  | "statistical_analysis"
  | "calculus"
  | "combinatorics"
  | "vectors"
  | "complex_numbers"
  | "mechanics"
  | "proof";

export type MathsAdvExtSubjectKey ="maths_advanced" | "maths_extension_1" | "maths_extension_2";

export const maths_adv_ext_topics: Record<MathsAdvExtTopicKey, Topic> = { 
    financial_mathematics: {
        name: "Financial Mathematics",
        formulas: [
        `\[A=P(1+r)^{n}\]`,
        `\[T_{n}=a+(n-1) d\]`,
        `\[S_{n}=\frac{n}{2}[2 a+(n-1) d]=\frac{n}{2}(a+l)\]`,
        `\[T_{n}=a r^{n-1}\]`,
        `\[S_{n}=\frac{a\left(1-r^{n}\right)}{1-r}=\frac{a\left(r^{n}-1\right)}{r-1}, r \neq 1\]`,
        `\[S=\frac{a}{1-r},|r|<1\]`,
    ]},
    functions: {
        name: "Functions",
        formulas:  [
        `\[x=\frac{-b \pm \sqrt{b^{2}-4 a c}}{2 a}\]`,
        `For \( a x^{3}+b x^{2}+c x+d=0 \) :\[\begin{aligned}\alpha+\beta+\gamma & =-\frac{b}{a} \\\alpha \beta+\alpha \gamma+\beta \gamma & =\frac{c}{a} \\\text { and } \alpha \beta \gamma & =-\frac{d}{a}\end{aligned}\]`,
        `\[(x-h)^{2}+(y-k)^{2}=r^{2}\]`,
        ]},
    logarithmic_and_exponential_functions: {
        name: "Logarithmic & Exponential Functions",
        formulas: [
        `\[log _{a} a^{x}=x=a^{\log _{a} x}\]`,
        `\[log _{a} x=\frac{\log _{b} x}{\log _{b} a} \]`,
        `\[a^{x}=e^{x \ln a}\]`,
    ]},
    trigonometric_functions: {
        name: "Trigonometric Functions",
        formulas: [
        `\[\sin A=\frac{\text { opp }}{\text { hyp }}\]`,
        `\[\cos A=\frac{\text { adj }}{\text { hyp }}\]`,
        `\[\tan A=\frac{\text { opp }}{\text { adj }}\]`,
        `\[A=\frac{1}{2} a b \sin C\]`,
        `\[\frac{a}{\sin A}=\frac{b}{\sin B}=\frac{c}{\sin C}\]`,
        `\[c^{2}=a^{2}+b^{2}-2 a b \cos C\]`,
        `\[\cos C=\frac{a^{2}+b^{2}-c^{2}}{2 a b}\]`,
        `\[l=r \theta\]`,
        `\[A=\frac{1}{2} r^{2} \theta\]`,
        `\[\sec A=\frac{1}{\cos A}, \cos A \neq 0\]`,
        `\[\operatorname{cosec} A=\frac{1}{\sin A}, \sin A \neq 0\]`,
        `\[\cot A=\frac{\cos A}{\sin A}, \sin A \neq 0\]`,
        `\[\cos ^{2} x+\sin ^{2} x=1\]`,
        `\[\sin (A+B)=\sin A \cos B+\cos A \sin B\]`,
        `\[\cos (A+B)=\cos A \cos B-\sin A \sin B\]`,
        `\[tan (A+B)=\frac{\tan A+\tan B}{1-\tan A \tan B}\]`,
        `If \( t=\tan \frac{A}{2} \) then \( \sin A=\frac{2 t}{1+t^{2}},\\ \cos A=\frac{1-t^{2}}{1+t^{2}}, \\ \tan A=\frac{2 t}{1-t^{2}}\)`,
        `\[\cos A \cos B=\frac{1}{2}[\cos (A-B)+\cos (A+B)] \]`,
        `\[\sin A \sin B=\frac{1}{2}[\cos (A-B)-\cos (A+B)]\]`,
        `\[\sin A \cos B=\frac{1}{2}[\sin (A+B)+\sin (A-B)]\]`,
        `\[\cos A \sin B=\frac{1}{2}[\sin (A+B)-\sin (A-B)]\]`,
        `\[\sin ^{2} n x=\frac{1}{2}(1-\cos 2 n x)\]`,
        `\[cos ^{2} n x=\frac{1}{2}(1+\cos 2 n x)\]`,
    ], 
    content: "If asked to draw a 3D diagram, follow these steps: 1. establish the the direction of North; 2. establish any angles of elevantion and distances or heights"
  },
    statistical_analysis: {
        name: "Statistical Analysis",
        formulas: [
        `\[z=\frac{x-\mu}{\sigma}\]`,
        `An outlier is a score less than \( Q_{1}-1.5 \times I Q R \) or more than \( Q_{3}+1.5 \times I Q R \)`,
        `approximately \( 68 \% \) of scores have \( z \)-scores between -1 and 1`,
        `approximately \( 95 \% \) of scores have \( z \)-scores between -2 and 2`,
        `approximately \( 99.7 \% \) of scores have \( z \)-scores between -3 and 3`,
        `\[E(X)=\mu\]`,
        `\[\operatorname{Var}(X)=E\left[(X-\mu)^{2}\right]=E\left(X^{2}\right)-\mu^{2}\]`,
        `\[P(A \cap B)=P(A) P(B)\]`,
        `\[P(A \cup B)=P(A)+P(B)-P(A \cap B)\]`,
        `\[P(A \mid B)=\frac{P(A \cap B)}{P(B)}, P(B) \neq 0\]`,
        `\[P(X \leq r)=\int_{a}^{r} f(x) d x\]`,
        `\[P(a<X<b)=\int_{a}^{b} f(x) d x\]`,
        `\[P(X=r)={ }^{n} C_{r} p^{r}(1-p)^{n-r}\]`,
        `\[X \sim \operatorname{Bin}(n, p)\Rightarrow P(X=x) \quad=\left(\begin{array}{l},n \\x\end{array}\right) p^{x}(1-p)^{n-x}, x=0,1, \ldots, n\]`,
        `\[E(X)=n p\]`,
        `\[\operatorname{Var}(X)=n p(1-p)\]`,
    ]},
    calculus: {
        name: "Derivatives & Integrals",
        formulas: [
        `\[y=f(x)^{n}, \frac{d y}{d x}=n f^{\prime}(x)[f(x)]^{n-1}\]`,
        `\[y=u v, \frac{d y}{d x}=u \frac{d v}{d x}+v \frac{d u}{d x}\]`,
        `\[y=g(u) \text { where } u=f(x), \frac{d y}{d x}=\frac{d y}{d u} \times \frac{d u}{d x}\]`,
        `\[y=\frac{u}{v}, \frac{d y}{d x}=\frac{v \frac{d u}{d x}-u \frac{d v}{d x}}{v^{2}}\]`,
        `\[y=\sin f(x), \frac{d y}{d x}=f^{\prime}(x) \cos f(x)\]`,
        `\[y=\cos f(x), \frac{d y}{d x}=-f^{\prime}(x) \sin f(x)\]`,
        `\[y=\tan f(x), \frac{d y}{d x}=f^{\prime}(x) \sec ^{2} f(x)\]`,
        `\[y=e^{f(x)}, \frac{d y}{d x}=f^{\prime}(x) e^{f(x)}\]`,
        `\[y=\ln f(x), \frac{d y}{d x}=\frac{f^{\prime}(x)}{f(x)}\]`,
        `\[y=a^{f(x)}, \frac{d y}{d x}=(\ln a) f^{\prime}(x) a^{f(x)}\]`,
        `\[y=\log _{a} f(x), \frac{d y}{d x}=\frac{f^{\prime}(x)}{(\ln a) f(x)}\]`,
        `\[y=\sin ^{-1} f(x), \frac{d y}{d x}=\frac{f^{\prime}(x)}{\sqrt{1-[f(x)]^{2}}}\]`,
        `\[y=\cos ^{-1} f(x), \frac{d y}{d x}=-\frac{f^{\prime}(x)}{\sqrt{1-[f(x)]^{2}}}\]`,
        `\[y=\tan ^{-1} f(x), \frac{d y}{d x}=\frac{f^{\prime}(x)}{1+[f(x)]^{2}}\]`,
        `\[\int f^{\prime}(x)[f(x)]^{n} d x=\frac{1}{n+1}[f(x)]^{n+1}+c, \text{ where } n \neq-1\]`,
        `\[\int f^{\prime}(x) \sin f(x) d x=-\cos f(x)+c\]`,
        `\[\int f^{\prime}(x) \cos f(x) d x=\sin f(x)+c\]`,
        `\[\int f^{\prime}(x) \sec ^{2} f(x) d x=\tan f(x)+c\]`,
        `\[\int f^{\prime}(x) e^{f(x)} d x=e^{f(x)}+c\]`,
        `\[\int \frac{f^{\prime}(x)}{f(x)} d x=\ln |f(x)|+c\]`,
        `\[\int f^{\prime}(x) a^{f(x)} d x=\frac{a^{f(x)}}{\ln a}+c\]`,
        `\[\int \frac{f^{\prime}(x)}{\sqrt{a^{2}-[f(x)]^{2}}} d x=\sin ^{-1} \frac{f(x)}{a}+c\]`,
        `\[\int \frac{f^{\prime}(x)}{a^{2}+[f(x)]^{2}} d x=\frac{1}{a} \tan ^{-1} \frac{f(x)}{a}+c\]`,
        `\[\int u \frac{d v}{d x} d x=u v-\int v \frac{d u}{d x} d x\]`,
        `\[\int_{a}^{b} f(x) d x \approx \frac{b-a}{2 n}\left\{f(a)+f(b)+2\left[f\left(x_{1}\right)+\cdots+f\left(x_{n-1}\right)\right]\right\} \text{ where } a=x_{0}, b=x_{n}\]`,
    ]},
    combinatorics: {
        name: "Combinatorics",
        formulas: [
        `\[{ }^{n} P_{r}=\frac{n !}{(n-r) !}\]`,
        `\[\left(\begin{array}{l}n \\ r\end{array}\right)={ }^{n} C_{r}=\frac{n !}{r !(n-r) !}\]`,
        `\[(x+a)^{n}=x^{n}+\left(\begin{array}{c}n \\ 1\end{array}\right) x^{n-1} a+\cdots+\left(\begin{array}{c}n \\ r\end{array}\right) x^{n-r} a^{r}+\cdots+a^{n}\]`,
    ]},
    vectors: {
        name: "Vectors",
        formulas: [
        String.raw`\[|\underset{\sim}{u}|=|x \underset{\sim}{i}+y \underset{\sim}{j}|=\sqrt{x^{2}+y^{2}}\]`,
        String.raw`\[\underset{\sim}{u} \cdot \underset{\sim}{v}=|\underset{\sim}{u}||\underset{\sim}{v}| \cos \theta=x_{1} x_{2}+y_{1} y_{2},\text { where } \underset{\sim}{u}=x_{1} \underset{\sim}{i}+y_{1} \underset{\sim}{j}, \text { and } \underset{\sim}{v}=x_{2} \underset{\sim}{i}+y_{2} \underset{\sim}{j}\]`,
        String.raw`\[\underset{\sim}{r}=\underset{\sim}{a}+\lambda \underset{\sim}{b}\]`
    ]},
    complex_numbers: {
        name: "Complex Numbers",
        formulas: [
        `\[z=a+i b=r(\cos \theta+i \sin \theta)=r e^{i \theta}\]`,
        `\[{[r(\cos \theta+i \sin \theta)]^{n} } =r^{n}(\cos n \theta+i \sin n \theta)=r^{n} e^{i n \theta}\]`,
    ]},
    mechanics: {
        name: "Mechanics",
        formulas: [
        `\[\frac{d^{2} x}{d t^{2}}=\frac{d v}{d t}=v \frac{d v}{d x}=\frac{d}{d x}\left(\frac{1}{2} v^{2}\right)\]`,
        `\[x=a \cos (n t+\alpha)+c\]`,
        `\[x=a \sin (n t+\alpha)+c\]`,
        `\[\ddot{x}=-n^{2}(x-c)\]`
    ]},
    proof: { 
        name: "Proof",
        // There are no formulas for Proof
        formulas: [], 
        content: `Use mathematical induction whenever possible. Use the terms statement, implication, converse, negation and contrapositive. Use the symbols for implication (⇒), equivalence (⇔) and equality (=). Use the phrases ‘for all’ (∀), ‘if and only if’ (iff) and ‘there exists’ (∃). Understand that a statement is equivalent to its contrapositive but that the converse of a true statement may not be true. Use proof by contradiction to prove the irrationality for numbers such as √2 and log25. Use examples and counter-examples. Prove results involving inequalities, for example: 𝑎 > 𝑏 for real 𝑎 and 𝑏; squares of real numbers are non-negative; triangle inequality |𝑥| + |𝑦| ≥ |𝑥 + 𝑦|. Use the relationship between the arithmetic mean and geometric mean for two non-negative numbers. Prove further results involving inequalities by logical use of previously obtained inequalities`
    },
 }

 export const maths_adv_ext_subjects: Record<MathsAdvExtSubjectKey, Subject> = {
    maths_advanced: {
      name: "Maths Advanced",
      topicKeys: [
        "calculus",
        "logarithmic_and_exponential_functions",
        "functions",
        "statistical_analysis",
        "trigonometric_functions",
        "financial_mathematics",
      ],
    },
    maths_extension_1: {
      name: "Maths Extension 1",
      topicKeys: [
        "calculus",
        "combinatorics",
        "functions",
        "trigonometric_functions",
        "proof",
        "statistical_analysis",
        "vectors",
      ],
    },
    maths_extension_2: {
      name: "Maths Extension 2",
      topicKeys: ["calculus", "complex_numbers", "mechanics", "proof", "vectors"],
    },
}
import { Subject, Topic } from "./subjects";

export type EconomicsTopicKey =
  | "economics_prelim_one"
  | "economics_prelim_two"
  | "economics_prelim_three"
  | "economics_prelim_four"
  | "economics_prelim_five"
  | "economics_prelim_six"
  | "economics_hsc_one"
  | "economics_hsc_two"
  | "economics_hsc_three"
  | "economics_hsc_four";

export type EconomicsSubjectKey = "economics";

export const economics_topics: Record<EconomicsTopicKey, Topic> = {
  economics_prelim_one: {
    name: "Prelim Topic 1 - Introduction to Economics",
    formulas: [],
    dotPoints: `
    - the economic problem (wants, resources, scarcity)
    - the need for choice by individuals and society
    - opportunity cost and its application through production possibility frontiers
    - future implications of current choices by individuals, businesses and governments
    - economic factors underlying decision-making by: individuals (spending, saving, work, education, retirement, voting and participation in the political process); business (pricing, production, resource use, industrial relations); governments (influencing the decisions of individuals and business)
    - production of goods and services from resources (natural, labour, capital and entrepreneurial resources)
    - distribution of goods and services
    - exchange of goods and services
    - provision of income
    - provision of employment and quality of life through the business cycle
    - the circular flow of income (individuals, businesses, financial institutions, governments, international trade and financial flows)
    - examine similarities and differences between Australia and at least one
    economy in Asia in relation to: economic growth and the quality of life; employment and unemployment; distribution of income; environmental sustainability; the role of government in health care, education and social welfare
    `,
  },
  economics_prelim_two: {
    name: "Prelim Topic 2 - Consumers and Business",
    formulas: [],
    dotPoints: `
    - Consumer sovereignty (variations of consumer spending and saving/dissaving patterns with income and age): individual consumers either spend or save their income; in the economy as a whole, as income rises the level of saving increases
    - Factors influencing individual consumer choice (income, price, price of substitutes, price of complements, preferences/tastes, advertising)
    - Sources of income (the return for resources: wages, rent, interest and profits; social welfare)
    - Definition of a firm and an industry: A firm's production decisions (what to produce, what quantities to produce, how to produce)
    - Business as a source of economic growth and increased productive capacity: Goals of the firm (maximising profits, maximising growth, increasing market share, meeting shareholder expectations, satisficing)
    - Efficiency and the production process (productivity, internal and external economies of scale, diseconomies of scale)
    - Impact of investment, technological change and ethical decision-making on a firm through: production methods, prices, employment, output, profits, types of products, globalisation, environmental sustainability.
    `,
  },
  economics_prelim_three: {
    name: "Prelim Topic 3 - Markets",
    formulas: [],
    dotPoints: `
    - The role of the market (determining solutions to the economic problem, the importance of relative price in reflecting opportunity costs in the goods and services and factor markets)
    - Demand: law of demand, individual and market demand, the demand curve; factors affecting demand (price, income, population, tastes, prices of substitutes and complements, expected future prices); movements along the demand curve and shifts of the demand curve 
    - Supply: law of supply, individual and market supply, the supply curve; factors affecting supply (price/cost of factors of production, prices of substitutes and complements, expected future prices, number of suppliers, technology); movements along the supply curve and shifts of the supply curve
    - Market price: market equilibrium (using diagrams); movement to equilibrium; effects of changes in supply and/or demand on equilibrium market price and
    quantity through the use of diagrams; effects of changing levels of competition and market power on price and output 
    - Alternatives to market solutions (the role of government): ceiling prices, floor prices; market failure (merit goods, public goods, externalities)
    - Price elasticity of demand: significance of price elasticity of demand (market research); price elasticity (elastic, inelastic and unit elastic; calculation of elasticity using total outlay method); factors affecting elasticity of demand (necessities and luxuries, existence of close substitutes, proportion of income spent on the good, the length of time since a price change)
    - Price elasticity of supply:  elastic supply, inelastic supply; factors affecting elasticity of supply (no calculations are required)
    - Market structures (pure competition, monopolistic competition, oligopoly, monopoly)
    `,
  },
  economics_prelim_four: {
    name: "Prelim Topic 4 - Labour Markets",
    formulas: [],
    dotPoints: `
    - The demand for labour by individual firms: labour (a derived demand); factors affecting demand (output of the firm; productivity of labour; cost of other inputs)
    - factors affecting the supply of labour: pay/remuneration; working conditions; human capital, skills, experience, education/training levels; occupational and geographic mobility of labour; participation rate 
    - The Australian workforce: definition of the workforce (employed, unemployed); general characteristics of the Australian workforce
    - Differences in incomes from work: wage outcomes for all persons by income groups, occupational groups, age, gender and cultural background; trends in the distribution of income from work over time; non-wage outcomes for different occupations; arguments for and against a more equitable distribution of income from work
    - Labour market trends (unemployment/underemployment, part-time work, casualisation of work, outsourcing, contractors, sub-contracting)
    - Labour market institutions (unions, employer associations, current employment /industrial framework)
    `,
  },
  economics_prelim_five: {
    name: "Prelim Topic 5 - Financial Markets",
    formulas: [],
    dotPoints: `
    - Types of financial markets: primary and secondary markets; consumer credit, housing loans, business loans, short term money market, bond
    market, financial futures, foreign exchange; the share market (its role, function and effect on the economy); domestic and global markets 
    - Regulation of financial markets: the role and functions of current institutions (Reserve Bank of Australia, Australian Prudential Regulation Authority, Australian Securities and Investments Commission, Australian Treasury, Council of Financial Regulators)
    - Borrowers: individuals, business, government
    - Factors affecting the demand for funds: transactions and speculative motives, financial innovations 
    - Lenders: individuals, business, government, international 
    - Financial aggregates measured by the Reserve Bank of Australia: currency, broad money, credit
    - Interest rates: types of rates in the short term and long term (lending rates, borrowing rates); role of the Reserve Bank of Australia in determining the cash rate; influence of the cash rate on interest rates.  
    `,
  },
  economics_prelim_six: {
    name: "Prelim Topic 6 - Government and the Economy",
    formulas: [],
    dotPoints: `
    - Limitations of the operation of the free market: provision of goods and services, public goods, merit goods; inequality in the distribution of income (disadvantaged groups, relative poverty); externalities and the environment (pollution, climate change); monopoly power (the formation of monopolies, government-owned monopolies, privatisation, corporatisation and competition); fluctuations in economic activity (the business cycle and the adverse effects of booms and recessions)
    - Functions of the three levels of government
    - Constitutional powers
    - Size of the public sector
    - Economic functions of the Australian Government: reallocation of resources (types of taxes: direct and indirect, types of expenses); redistribution of income (progressive, regressive and proportional taxes, social welfare payments); stablisation of economic activity (a brief overview of the stabilising role of monetary and fiscal policies); government business enterprises; other (including competition and environmental policies)
    - Federal Budget: the budget process; types of budgets (surplus, balanced, deficit); revenue and expenses 
    - Influences on government policies in Australia: political parties; business; unions; environmental groups/organisations; welfare agencies; the media; other interest groups; international.
    `,
  },
  economics_hsc_one: {
    name: "HSC Topic 1 - The Global Economy",
    formulas: [],
    dotPoints: `
    - the global economy
    - Gross World Product 
    - globalisation: trade in goods and services; financial flows; investment and transnational corporations; technology, transport and communication; international division of labour, migration
    - the international and regional business cycles 
    - the basis of free trade (its advantages and disadvantages)
    - role of international organisations (WTO, IMF, World Bank, United Nations, OECD)
    - influence of government economic forums (G20, G7/8)
    - trading blocs, monetary unions and free trade agreements: advantages and disadvantages of multilateral (EU, APEC, NAFTA, ASEAN) and bilateral agreements 
    - reasons for protection: infant industry argument, domestic employment, dumping, defence
    - methods of protection and the effects of protectionist policies on the domestic and global economy: tariffs, subsidies, quotas, local content rules, export incentives 
    - Globalisation and economic development: differences between economic growth and economic development; distribution of income and wealth; income and quality of life indicators; developing economies, emerging economies, advanced economies; reasons for differences between nations; effects of globalisation; trade, investment and transnational corporations; environmental sustainability; the international business cycle.
    `,
  },
  economics_hsc_two: {
    name: "HSC Topic 2 - Australia's Place in the Global Economy",
    formulas: [],
    dotPoints: `
    - Value, composition and direction of Australia's trade and financial flows: trends in Australia's trade pattern; trends in financial flows (debt and equity)
    - Australia's Balance of Payments: structure (Current Account, debits and credits; Capital and Financial Account); links between key Balance of Payments categories; trends in the size and composition of Australia's Balance of Payments (international competitiveness, terms of trade, international borrowing, foreign investment; effects of these trends on Australia's Balance of Payments) 
    - measurement of relative exchange rates: to other individual currencies; Trade Weighted Index 
    - factors affecting the demand for and supply of Australian dollars
    - changes in exchange rates: appreciation/depreciation
    - determination of exchange rates including fixed, flexible and managed rates
    - the influence of the Reserve Bank of Australia on exchange rates
    - the effects of fluctuations in exchange rates on the Australian economy 
    - Australia's policies regarding free trade and protection
    - Australia's multilateral and bilateral free trade agreements
    - the implications of Australia's policies for individuals, firms and governments
    - implications for Australia of protectionist policies of other countries and trading blocs.
    `,
  },
  economics_hsc_three: {
    name: "HSC Topic 3 - Economic Issues",
    formulas: [],
    dotPoints: `
    - Economic growth: aggregate demand and its components: Y = C+I+G+X-M; injections and withdrawals (I+G+X; S+T+M); the simple multiplier: k = 1/(1-MPC); measurement of growth through changes in real Gross Domestic Product; sources and effects of economic growth in Australia; increases in aggregate supply (improvements in efficiency and technology); trends in business cycle 
    - Unemployment: measurement (labour force, participation rate, unemployment rate); trends; types and causes (cyclical, structural, frictional, seasonal, underemployment, hidden, long term); non-accelerating inflation rate of unemployment (NAIRU); main groups affected by unemployment; effects of unemployment (economic and social costs)
    - Inflation: measurement (headline and underlying); trends; causes (demand inflation, cost inflation, imported inflation, inflationary expectations); positive and negative effects 
    - External stability: measurement (Current Account Deficit (CAD) as a percentage of Gross Domestic Product, net foreign debt as a percentage of Gross Domestic Product, net foreign liabilities as a percentage of Gross Domestic Product, terms of trade, exchange rate, international competitiveness); trends; positive and negative causes and effects
    - Distribution of income and wealth: measurement (Lorenz curve and Gini coefficient); sources of income as a percentage of household income; taxation, transfer payments and other assistance; sources of wealth; dimensions and trends, according to gender, age, occupation, ethnic background and family structure; economic and social costs and benefits of inequality
    - Environmental sustainability: ecologically sustainable development; private and social costs and benefits (externalities, market failure); public and private goods (free riders); environmental issues (preservation of natural environments, pollution / climate change, depletion of renewable and non-renewable resources)
    `,
  },
  economics_hsc_four: {
    name: "HSC Topic 4 - Economic Policies and Management",
    formulas: [],
    dotPoints: `
    - Economic objectives in relation to: economic growth and quality of life; full employment; price stability; external stability; environmental sustainability; distribution of income
    - rationale for macroeconomic policies: stabilisation and shifts in aggregate demand 
    - Fiscal policy: Federal Government budgets and budget outcomes; effects of budgetary changes on resource use, income distribution and economic activity; methods of financing deficits; use of a surplus 
    - Monetary policy: purpose of monetary policy; implementation of monetary policy by the Reserve Bank of Australia; impact of changes in interest rates on economic activity and the exchange rate
    - rationale for microeconomic policies including shifts in aggregate supply, efficiency
    - effects of microeconomic policies on individual product and factor markets, individual industries and the economy
    - regulation and deregulation (competition policy)
    - Labour market policies: role of national and state systems; the national system for determining (minimum employment standards, minimum wages, awards, enterprise agreements, employment contracts for high income earners); dispute resolution; arguments for and against the use of centralised, decentralised and individualised methods of determining employment contracts; education, training and employment programs 
    - National and global context for environmental management: regulations, market-based policies, targets, international agreements
    - Limitations of economic policies: time lags, global influences, political constraints
    - Policy responses and their effects in dealing with the economic objectives: economic growth and quality of life; full employment; price stability; external stability; distribution of income; environmental sustainability. 
    `,
  },
};

export const economics_subjects: Record<EconomicsSubjectKey, Subject> = {
  economics: {
    name: "Economics",
    topicKeys: [
      "economics_prelim_one",
      "economics_prelim_two",
      "economics_prelim_three",
      "economics_prelim_four",
      "economics_prelim_five",
      "economics_prelim_six",
      "economics_hsc_one",
      "economics_hsc_two",
      "economics_hsc_three",
      "economics_hsc_four",
    ],
  },
};

import {
  BiologySubjectKey,
  BiologyTopicKey,
  biology_subjects,
  biology_topics,
} from "./biology";
import {
  ChemistrySubjectKey,
  ChemistryTopicKey,
  chemistry_subjects,
  chemistry_topics,
} from "./chemistry";
import {
  MathsAdvExtSubjectKey,
  MathsAdvExtTopicKey,
  maths_adv_ext_subjects,
  maths_adv_ext_topics,
} from "./maths_adv_ext";
import {
  MathsStandardSubjectKey,
  MathsStandardTopicKey,
  maths_standard_subjects,
  maths_standard_topics,
} from "./maths_standard";
import {
  PhysicsSubjectKey,
  PhysicsTopicKey,
  physics_subjects,
  physics_topics,
} from "./physics";
import {
  EconomicsSubjectKey,
  EconomicsTopicKey,
  economics_subjects,
  economics_topics,
} from "./economics";

export type Topic = {
  name: string;
  formulas: string[];
  content?: string;
  constants?: string;
  dotPoints?: string;
};

export type Subject = {
  name: string;
  topicKeys: TopicKey[];
};

export type TopicKey =
  | MathsAdvExtTopicKey
  | PhysicsTopicKey
  | ChemistryTopicKey
  | MathsStandardTopicKey
  | BiologyTopicKey
  | EconomicsTopicKey;

export type SubjectKey =
  | MathsAdvExtSubjectKey
  | PhysicsSubjectKey
  | ChemistrySubjectKey
  | MathsStandardSubjectKey
  | BiologySubjectKey
  | EconomicsSubjectKey;

export const topics: Record<TopicKey, Topic> = {
  ...maths_adv_ext_topics,
  ...physics_topics,
  ...chemistry_topics,
  ...maths_standard_topics,
  ...biology_topics,
  ...economics_topics,
};

export const subjects: Record<SubjectKey, Subject> = {
  ...maths_standard_subjects,
  ...maths_adv_ext_subjects,
  ...physics_subjects,
  ...chemistry_subjects,
  ...biology_subjects,
  ...economics_subjects,
};

"use client";

import { MAX_MESSAGES_IN_CHAT } from "@/utils/consts";
import { Message } from "@/utils/helpers";
import classNames from "classnames";
import { Lock, Send } from "lucide-react";
import { FC, useEffect, useState } from "react";
import UpgradeModal from "../modal/UpgradeModal";
import MessageReadonly from "./MessageReadonly";
import { useSessionContext } from "@/context/useSessionContext";
import mixpanel from "mixpanel-browser";
import { hasFullAccess } from "@/utils/helpers";

type Props = {
  messages: Message[];
  handleFollowUpMessage?: (content: string) => void;
  readonly?: boolean;
};

const Conversation: FC<Props> = ({
  messages,
  handleFollowUpMessage,
  readonly,
}) => {
  const [followUpMessage, setFollowUpMessage] = useState("");
  const handleSend = () => {
    if (!handleFollowUpMessage || !followUpMessage.replace(/\s+/g, "")) return;
    handleFollowUpMessage(followUpMessage);
    setFollowUpMessage("");
  };
  const [limitFollowUp, setLimitFollowUp] = useState(true);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const { profile } = useSessionContext();

  useEffect(() => {
    if (hasFullAccess(profile ?? undefined)) {
      setLimitFollowUp(false);
    }
  }, [profile]);

  return (
    <>
      <div className="relative h-full bg-white border-primary-10/50 shadow rounded-md border">
        <div className="h-full overflow-y-scroll text-sm rounded-md text-neutral-10">
          <div className="flex flex-col divide-y grow divide">
            {messages.map((msg) => (
              <MessageReadonly
                isUser={msg.isUser}
                key={msg.messageContent}
                content={msg.messageContent}
                photoData={msg.photoData}
              />
            ))}
            {messages[messages.length - 1].isUser && (
              <MessageReadonly isUser={false} />
            )}
          </div>
          <div className="block h-40 shrink-0" />
        </div>
        {!readonly && (
          <div className="absolute bottom-0 flex items-center w-full px-4 pb-10 sm:px-24 md:px-8 xl:px-24 bg-gradient-to-b from-transparent via-white to-white rounded-b-md">
            <textarea
              name=""
              id=""
              rows={1}
              placeholder="Ask a follow up question."
              className="w-full px-4 py-3 text-sm border rounded-lg shadow resize-none outline-0 placeholder:text-sm"
              value={followUpMessage}
              onChange={(e) => setFollowUpMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  e.preventDefault();
                  if (
                    limitFollowUp &&
                    messages.length >= MAX_MESSAGES_IN_CHAT
                  ) {
                    setShowUpgradeModal(true);
                    mixpanel.track(
                      "Triggered upgrade modal: follow up limit reached"
                    );
                  } else {
                    handleSend();
                  }
                }
              }}
            />

            {limitFollowUp && messages.length >= MAX_MESSAGES_IN_CHAT ? (
              <Lock
                strokeWidth={2.4}
                onClick={() => {
                  setShowUpgradeModal(true);
                  mixpanel.track(
                    "Triggered upgrade modal: follow up limit reached"
                  );
                }}
                className="w-5 h-5 cursor-pointer -ml-10 text-primary-6"
              />
            ) : (
              <Send
                strokeWidth={2.4}
                onClick={handleSend}
                className={classNames(
                  "w-5 h-5 cursor-pointer -ml-10",
                  followUpMessage.replace(/\s+/g, "")
                    ? "text-primary-6"
                    : "text-neutral-3"
                )}
              />
            )}
          </div>
        )}
        <div className="absolute bottom-0 w-full py-3 px-1 text-xs tracking-tight text-center rounded-b-md text-neutral-5 bg-gradient-to-b from-white/50 via-white to-white">
          Stella can make mistakes. When in doubt, consider checking with a
          human tutor.
        </div>
      </div>
      <UpgradeModal
        onOpenChange={setShowUpgradeModal}
        show={showUpgradeModal}
        paywalled={true}
      />
    </>
  );
};

export default Conversation;

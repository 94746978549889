import { Subject, Topic } from "./subjects";

export type BiologyTopicKey =
  | "biology_mod_one"
  | "biology_mod_two"
  | "biology_mod_three"
  | "biology_mod_four"
  | "biology_mod_five"
  | "biology_mod_six"
  | "biology_mod_seven"
  | "biology_mod_eight";

export type BiologySubjectKey = "biology";

export const biology_topics: Record<BiologyTopicKey, Topic> = {
  biology_mod_one: {
    name: "Mod 1 (Cells as the Basis of Life)",
    formulas: [],
    dotPoints: `## Cell Structure
    - investigate different cellular structures, including but not limited to:
     - examining a variety of prokaryotic and eukaryotic cells (ACSBL032, ACSBL048) 📷
     - describe a range of technologies that are used to determine a cell's structure and function 📷
    - investigate a variety of prokaryotic and eukaryotic cell structures, including but not limited to:
     - drawing scaled diagrams of a variety of cells (ACSBL035) 📷 📝
     - comparing and contrasting different cell organelles and arrangements 🔬
     - modelling the structure and function of the fluid mosaic model of the cell membrane (ACSBL045) 🔬 📷
    
    ## Cell Function
    - investigate the way in which materials can move into and out of cells, including but not limited to:
     - conducting a practical investigation modelling diffusion and osmosis (ACSBL046) 📷
     - examining the roles of active transport, endocytosis and exocytosis (ACSBL046)
     - relating the exchange of materials across membranes to the surface-area-to-volume ratio, concentration gradients and characteristics of the materials being exchanged (ACSBL047) 📷 📝
    - investigate cell requirements, including but not limited to:
     - suitable forms of energy, including light energy and chemical energy in complex molecules (ACSBL044)
     - matter, including gases, simple nutrients and ions
     - removal of wastes (ACSBL044)
    - investigate the biochemical processes of photosynthesis, cell respiration and the removal of cellular products and wastes in eukaryotic cells (ACSBL049, ACSBL050, ACSBL052, ACSBL053) 📷
    - conduct a practical investigation to model the action of enzymes in cells (ACSBL050)
    - investigate the effects of the environment on enzyme activity through the collection of primary or secondary data (ACSBL050, ACSBL051)`,
  },
  biology_mod_two: {
    name: "Mod 2 (Organisation of Living Things)",
    formulas: [],
    dotPoints: `
    ## Organisation of Cells
    - compare the differences between unicellular, colonial and multicellular organisms by:
      - investigating structures at the level of the cell and organelle
      - relating structure of cells and cell specialisation to function
    - investigate the structure and function of tissues, organs and systems and relate those functions to cell differentiation and specialisation (ACSBL055) 📷
    - justify the hierarchical structural organisation of organelles, cells, tissues, organs, systems and organisms (ACSBL054) 🔬

    ## Nutrient and Gas Requirements
    - investigate the structure of autotrophs through the examination of a variety of materials, for example: (ACSBL035) 📷
      - dissected plant materials (ACSBL032)
      - microscopic structures
      - using a range of imaging technologies to determine plant structure 📷
    - investigate the function of structures in a plant, including but not limited to:
      - tracing the development and movement of the products of photosynthesis (ACSBL059, ACSBL060) 📷
    - investigate the gas exchange structures in animals and plants (ACSBL032, ACSBL056) through the collection of primary and secondary data and information, for example:
      - microscopic structures: alveoli in mammals and leaf structure in plants 📷 🍂
      - macroscopic structures: respiratory systems in a range of animals 📷 🐟
    - interpret a range of secondary-sourced information to evaluate processes, claims and conclusions that have led scientists to develop hypotheses, theories and models about the structure and function of plants, including but not limited to: (ACSBL034) 🔬 📷 🍂
      - photosynthesis
      - transpiration-cohesion-tension theory
    - trace the digestion of foods in a mammalian digestive system, including: 📷 🍔
      - physical digestion
      - chemical digestion
      - absorption of nutrients, minerals and water
      - elimination of solid waste
    - compare the nutrient and gas requirements of autotrophs and heterotrophs 🍂 📷

    ## Transport
    - investigate transport systems in animals and plants by comparing structures and components using physical and digital models, including but not limited to: (ACSBL032, ACSBL058, ACSBL059, ACSBL060) 📷 🍂
      - macroscopic structures in plants and animals
      - microscopic samples of blood, the cardiovascular system and plant vascular systems 📷
    - investigate the exchange of gases between the internal and external environments of plants and animals 📷 🍂
    - compare the structures and function of transport systems in animals and plants, including but not limited to: (ACSBL033) 🍂
      - vascular systems in plants and animals
      - open and closed transport systems in animals
    - compare the changes in the composition of the transport medium as it moves around an organism`,
  },
  biology_mod_three: {
    name: "Mod 3 (Biological Diversity)",
    formulas: [],
    dotPoints: `
    ## Adaptations
    - conduct practical investigations, individually or in teams, or use secondary sources to examine the adaptations of organisms that increase their ability to survive in their environment, including:
      - structural adaptations
      - physiological adaptations
      - behavioural adaptations
    - investigate, through secondary sources, the observations and collection of data that were obtained by Charles Darwin to support the Theory of Evolution by Natural Selection, for example:
      - finches of the Galapagos Islands
      - Australian flora and fauna

    ## Theory of Evolution by Natural Selection
    - explain biological diversity in terms of the Theory of Evolution by Natural Selection by examining the changes in and diversification of life since it first appeared on the Earth (ACSBL088)
    - analyse how an accumulation of microevolutionary changes can drive evolutionary changes and speciation over time, for example: (ACSBL034, ACSBL093)
      - evolution of the horse
      - evolution of the platypus
    - explain, using examples, how Darwin and Wallace's Theory of Evolution by Natural Selection accounts for:
      - convergent evolution
      - divergent evolution
    - explain how punctuated equilibrium is different from the gradual process of natural selection

    ## Evolution – the Evidence
    - investigate, using secondary sources, evidence in support of Darwin and Wallace's Theory of Evolution by Natural Selection, including but not limited to:
      - biochemical evidence, comparative anatomy, comparative embryology and biogeography (ACSBL089)
      - techniques used to date fossils and the evidence produced
    - explain modern-day examples that demonstrate evolutionary change, for example:
      - the cane toad
      - antibiotic-resistant strains of bacteria`,
  },
  biology_mod_four: {
    name: "Mod 4 (Ecosystem Dynamics)",
    formulas: [],
    dotPoints: `
    ## Population Dynamics
    - investigate and determine relationships between biotic and abiotic factors in an ecosystem, including: (ACSBL019)
      - the impact of abiotic factors (ACSBL021, ACSBL022, ACSBL025)
      - the impact of biotic factors, including predation, competition and symbiotic relationships (ACSBL024)
      - the ecological niches occupied by species (ACSBL023)
      - predicting consequences for populations in ecosystems due to predation, competition, symbiosis and disease (ACSBL019, ACSBL020)
      - measuring populations of organisms using sampling techniques (ACSBL003, ACSBL015)
    - explain a recent extinction event (ACSBL024)

    ## Past Ecosystems
    - analyse palaeontological and geological evidence that can be used to provide evidence for past changes in ecosystems, including but not limited to:
      - Aboriginal rock paintings
      - rock structure and formation
      - ice core drilling
    - investigate and analyse past and present technologies that have been used to determine evidence for past changes, for example: (ACSBL005)
      - radiometric dating
      - gas analysis
    - analyse evidence that present-day organisms have evolved from organisms in the past by examining and interpreting a range of secondary sources to evaluate processes, claims and conclusions relating to the evolution of organisms in Australia, for example: (ACSBL005, ACSBL027)
      - small mammals
      - sclerophyll plants
    - investigate the reasons for changes in past ecosystems, by:
      - interpreting a range of secondary sources to develop an understanding of the changes in biotic and abiotic factors over short and long periods of time (ACSBL025, ACSBL026)
      - evaluating hypotheses that account for identified trends (ACSBL001)

    ## Future Ecosystems
    - investigate changes in past ecosystems that may inform our approach to the management of future ecosystems, including:
      - the role of human-induced selection pressures on the extinction of species (ACSBL005, ACSBL028, ACSBL095)
      - models that humans can use to predict future impacts on biodiversity (ACSBL029, ACSBL071)
      - the role of changing climate on ecosystems
    - investigate practices used to restore damaged ecosystems, Country or Place, for example:
      - mining sites
      - land degradation from agricultural practices`,
  },
  biology_mod_five: {
    name: "Mod 5 (Heredity)",
    formulas: [],
    dotPoints: `
    ## Reproduction
    - explain the mechanisms of reproduction that ensure the continuity of a species, by analysing sexual and asexual methods of reproduction in a variety of organisms, including but not limited to:
      - animals: advantages of external and internal fertilisation
      - plants: asexual and sexual reproduction
      - fungi: budding, spores
      - bacteria: binary fission (ACSBL075)
      - protists: binary fission, budding
    - analyse the features of fertilisation, implantation and hormonal control of pregnancy and birth in mammals (ACSBL075)
    - evaluate the impact of scientific knowledge on the manipulation of plant and animal reproduction in agriculture (ACSBL074)

    ## Cell Replication
    - model the processes involved in cell replication, including but not limited to:
      - mitosis and meiosis (ACSBL075)
      - DNA replication using the Watson and Crick DNA model, including nucleotide composition, pairing and bonding (ACSBL076, ACSBL077)
    - assess the effect of the cell replication processes on the continuity of species (ACSBL084)

    ## DNA and Polypeptide Synthesis
    - construct appropriate representations to model and compare the forms in which DNA exists in eukaryotes and prokaryotes (ACSBL076)
    - model the process of polypeptide synthesis, including: (ACSBL079)
      - transcription and translation
      - assessing the importance of mRNA and tRNA in transcription and translation (ACSBL079)
      - analysing the function and importance of polypeptide synthesis (ACSBL080)
      - assessing how genes and environment affect phenotypic expression (ACSBL081)
    - investigate the structure and function of proteins in living things

    ## Genetic Variation
    - conduct practical investigations to predict variations in the genotype of offspring by modelling meiosis, including the crossing over of homologous chromosomes, fertilisation and mutations (ACSBL084)
    - model the formation of new combinations of genotypes produced during meiosis, including but not limited to: 
      - interpreting examples of autosomal, sex-linkage, co-dominance, incomplete dominance and multiple alleles (ACSBL085)
      - constructing and interpreting information and data from pedigrees and Punnett squares
    - collect, record and present data to represent frequencies of characteristics in a population, in order to identify trends, patterns, relationships and limitations in data, for example:
      - examining frequency data
      - analysing single nucleotide polymorphism (SNP)

    ## Inheritance Patterns in a Population
    - investigate the use of technologies to determine inheritance patterns in a population using, for example: (ACSBL064, ACSBL085)
      - DNA sequencing and profiling (ACSBL086)
    - investigate the use of data analysis from a large-scale collaborative project to identify trends, patterns and relationships, for example: (ACSBL064, ACSBL073)
      - the use of population genetics data in conservation management
      - population genetics studies used to determine the inheritance of a disease or disorder
      - population genetics relating to human evolution
    `,
  },
  biology_mod_six: {
    name: "Mod 6 (Genetic Change)",
    formulas: [],
    dotPoints: `
    ## Mutation
    - explain how a range of mutagens operate, including but not limited to:
      - electromagnetic radiation sources
      - chemicals
      - naturally occurring mutagens
    - compare the causes, processes and effects of different types of mutation, including but not limited to:
      - point mutation
      - chromosomal mutation
    - distinguish between somatic mutations and germ-line mutations and their effect on an organism (ACSBL082, ACSBL083)
    - assess the significance of 'coding' and 'non-coding' DNA segments in the process of mutation (ACSBL078)
    - investigate the causes of genetic variation relating to the processes of fertilisation, meiosis and mutation (ACSBL078)

    ## Biotechnology
    - investigate the uses and applications of biotechnology (past, present and future), including: (ACSBL087)
      - analysing the social implications and ethical uses of biotechnology, including plant and animal examples
      - researching future directions of the use of biotechnology
      - evaluating the potential benefits for society of research using genetic technologies
      - evaluating the changes to the Earth's biodiversity due to genetic techniques

    ## Genetic Technologies
    - investigate the uses and advantages of current genetic technologies that induce genetic change
    - compare the processes and outcomes of reproductive technologies, including but not limited to:
      - artificial insemination
      - artificial pollination
    - investigate and assess the effectiveness of cloning, including but not limited to:
      - whole organism cloning
      - gene cloning
    - describe techniques and applications used in recombinant DNA technology, for example:
      - the development of transgenic organisms in agricultural and medical applications (ACSBL087)
    - evaluate the benefits of using genetic technologies in agricultural, medical and industrial applications (ACSBL086)
    - evaluate the effect on biodiversity of using biotechnology in agriculture
    - interpret a range of secondary sources to assess the influence of social, economic and cultural contexts on a range of biotechnologies
    `,
  },
  biology_mod_seven: {
    name: "Mod 7 (Infectious Disease)",
    formulas: [],
    dotPoints: `
    ## Causes of Infectious Disease
    - describing different pathogens that cause disease in plants and animals (ACSBL117)
    - investigating the transmission of a disease during an epidemic
    - design and conduct a practical investigation relating to the microbial testing of water or food samples
    - investigate modes of transmission of infectious diseases, including direct contact, indirect contact and vector transmission
    - Koch's postulates
    - Pasteur's experiments on microbial contamination
    - plant diseases
    - animal diseases
    - compare the adaptations of different pathogens that facilitate their entry into and transmission between hosts (ACSBL118)

    ## Responses to Pathogens
    - investigate the response of a named Australian plant to a named pathogen through practical and/or secondary-sourced investigation, for example:
    - fungal pathogens
    - viral pathogens
    - analyse responses to the presence of pathogens by assessing the physical and chemical changes that occur in the host animals cells and tissues (ACSBL119, ACSBL120, ACSBL121, ACSBL122)

    ## Immunity
    - investigate and model the innate and adaptive immune systems in the human body (ACSBL119)
    - explain how the immune system responds after primary exposure to a pathogen, including innate and acquired immunity

    ## Prevention, Treatment and Control
    - investigate and analyse the wide range of interrelated factors involved in limiting local, regional and global spread of a named infectious disease
    - investigate procedures that can be employed to prevent the spread of disease, including but not limited to: (ACSBL124)
    - hygiene practices
    - quarantine
    - vaccination, including passive and active immunity (ACSBL100, ACSBL123)
    - public health campaigns
    - use of pesticides
    - genetic engineering
    - investigate and assess the effectiveness of pharmaceuticals as treatment strategies for the control of infectious disease, for example:
    - antivirals
    - antibiotics
    - investigate and evaluate environmental management and quarantine methods used to control an epidemic or pandemic
    - interpret data relating to the incidence and prevalence of infectious disease in populations, for example:
    - mobility of individuals and the portion that are immune or immunised (ACSBL124, ACSBL125)
    - Malaria or Dengue Fever in South East Asia
    - evaluate historical, culturally diverse and current strategies to predict and control the spread of disease (ACSBL125)
    - investigate the contemporary application of Aboriginal protocols in the development of particular medicines and biological materials in Australia and how recognition and protection of Indigenous cultural and intellectual property is important, for example:
    - bush medicine
    - smoke bush in Western Australia
    `,
  },
  biology_mod_eight: {
    name: "Mod 8 (Non-infectious Disease and Disorders)",
    formulas: [],
    dotPoints: `
    ## Homeostasis
    - construct and interpret negative feedback loops that show homeostasis by using a range of sources, including but not limited to: (ACSBL101, ACSBL110, ACSBL111)
    - temperature (ACSBL098)
    - glucose
    - investigate the various mechanisms used by organisms to maintain their internal environment within tolerance limits, including:
    - trends and patterns in behavioural, structural and physiological adaptations in endotherms that assist in maintaining homeostasis (ACSBL099, ACSBL114)
    - internal coordination systems that allow homeostasis to be maintained, including hormones and neural pathways (ACSBL112, ACSBL113, ACSBL114)
    - mechanisms in plants that allow water balance to be maintained (ACSBL115)

    ## Causes and Effects
    - investigate the causes and effects of non-infectious diseases in humans, including but not limited to:
    - genetic diseases
    - diseases caused by environmental exposure
    - nutritional diseases
    - cancer
    - collect and represent data to show the incidence, prevalence and mortality rates of non-infectious diseases, for example:
    - nutritional diseases
    - diseases caused by environmental exposure

    ## Epidemiology
    - analyse patterns of non-infectious diseases in populations, including their incidence and prevalence, including but not limited to:
    - nutritional diseases
    - diseases caused by environmental exposure
    - investigate the treatment/management, and possible future directions for further research, of a non-infectious disease using an example from one of the non-infectious diseases categories listed above
    - evaluate the method used in an example of an epidemiological study
    - evaluate, using examples, the benefits of engaging in an epidemiological study

    ## Prevention
    - use secondary sources to evaluate the effectiveness of current disease-prevention methods and develop strategies for the prevention of a non-infectious disease, including but not limited to:
    - educational programs and campaigns
    - genetic engineering

    ## Technologies and Disorders
    - explain a range of causes of disorders by investigating the structures and functions of the relevant organs, for example:
    - hearing loss
    - visual disorders
    - loss of kidney function
    - investigate technologies that are used to assist with the effects of a disorder, including but not limited to: (ACSBL100)
    - hearing loss: cochlear implants, bone conduction implants, hearing aids
    - visual disorders: spectacles, laser surgery
    - loss of kidney function: dialysis
    - evaluate the effectiveness of a technology that is used to manage and assist with the effects of a disorder (ACSBL100)
    `,
  },
};

export const biology_subjects: Record<BiologySubjectKey, Subject> = {
  biology: {
    name: "Biology",
    topicKeys: [
      "biology_mod_one",
      "biology_mod_two",
      "biology_mod_three",
      "biology_mod_four",
      "biology_mod_five",
      "biology_mod_six",
      "biology_mod_seven",
      "biology_mod_eight",
    ],
  },
};
